import ImageCropperMixin from "@Platon/mixins/ImageCropperMixin"

export default {
	mixins: [ImageCropperMixin],

	methods: {
		/**
		 * @param {PlatonFormElement} item
		 *
		 * @return null|Cropper.Options
		 */
		getCropOptionsForElement(item) {
			return item.imageCropOptions
		},

		/**
		 * @param {File[]|File} files
		 * @param {Cropper.Options} cropperOptions
		 * @return {Promise<File[]|File>}
		 */
		async tryToCrop(files, cropperOptions = {}) {
			if (Array.isArray(files)) {
				const results = []

				for (let file of files) {
					try {
						results.push(await this.cropImage(file, cropperOptions))
					} catch (e) {
						console.log(e.message)
					}
				}

				return results
			} else {
				return await this.cropImage(files, cropperOptions)
			}
		}
	}
}
