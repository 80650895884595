<template>
    <div class="image-upload-camera">
        <div class="image-upload-camera-modal">
            <section class="cameraModal plt-camera-stream-root">
                <header class="cameraModal__header">
                    <div class="cameraModal__header--close" @click="toggleCloseModal">
                        <i class="fa fa-times" />
                    </div>
                </header>
                <main class="cameraModal__main plt-camera-stream-main">
                    <div
                        class="cameraModal__main--display plt-camera-stream plt-camera-focus-box"
                        v-show="!state.error.is"
                    >
                        <video
                            playsinline
                            ref="camera"
                            class="stream-video"
                            style="width: 100%"
                            :style="{ transform: shouldFaceUser ? 'scale(-1, 1)' : '' }"
                            v-show="state.isCameraOpen"
                        />

                        <canvas
                            ref="canvas"
                            style="width: 100%"
                            class="capture-canvas"
                            :style="{ transform: shouldFaceUser ? 'scale(-1, 1)' : '' }"
                            id="stream-screen-picture-canvas"
                            v-show="state.isPhotoTaken"
                        />
                    </div>
                    <div class="cameraModal__main--dialog" v-if="state.error.is">
                        <i class="fa fa-exclamation-circle capture-error-icon" />
                        <div class="capture-error-message">
                            {{ state.error.message }}
                        </div>
                    </div>
                    <div class="cameraModal__main--circularProgress" v-if="!state.isInitialized">
                        <div class="progress-icon" />
                    </div>
                    <div
                        type="button"
                        @click="camera"
                        class="cameraModal__main-rear"
                        :class="!shouldFaceUser ? 'rearcamera' : ''"
                        v-show="!state.isPhotoTaken || state.isInitialized"
                    >
                        <i class="fa fa-retweet" />
                    </div>
                </main>

                <div class="cameraModal__subheader">
                    <div class="cameraModal__subheader--options" v-if="!state.error.is">
                        <div class="camera-option-button" @click="takePhotoButton" v-show="!state.isPhotoTaken">
                            <i class="fa fa-camera" />
                        </div>

                        <div class="camera-option-button" @click="reopenCamera" v-show="state.isPhotoTaken">
                            <i class="fa fa-undo" />
                        </div>

                        <div
                            :class="`camera-option-button ${!state.isPhotoTaken && 'disabled'}`"
                            @click="sendTakenPictureTo"
                        >
                            <i class="fa fa-save" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { isMobile } from "@Platon/core/helpers"

const initialCameraState = {
    isCameraOpen: false,
    isPhotoTaken: false,
    isInitialized: false,
    error: {
        is: false,
        message: "Камерангизга кириш имкони бўлмади, ёки топилмади."
    }
}

export default {
    name: "ImageUploadByCamera",
    data() {
        return {
            state: initialCameraState,
            stream: null,
            shouldFaceUser: true,
            isMobile: !isMobile()
        }
    },
    mounted() {
        this.detectionWebcamAvailability()
    },
    methods: {
        // async capture() {
        //     let constraints = (window.constraints = { audio: false, video: true })

        //     constraints.video = window.constraints.video = {
        //         facingMode: this.shouldFaceUser ? "user" : "environment"
        //     }

        //     try {
        //         const navigatorResponse = await navigator.mediaDevices.getUserMedia(constraints)
        //         this.$refs.camera.srcObject = navigatorResponse
        //         this.$refs.camera.play()
        //     } catch (error) {
        //         this.action("error")
        //         throw new Error(error)
        //     }
        // },
        async camera() {
            if (this.stream == null) return
            // we need to flip, and stop everything
            await this.stream.getTracks().forEach(async (t) => {
                await t.stop()
            })
            // toggle / flip
            this.shouldFaceUser = !this.shouldFaceUser

            if (this.shouldFaceUser) {
                // await this.capture()
                await this.accessDeviceCamera()
            } else {
                await this.detectionWebcamAvailability()
            }
        },

        toggleCloseModal() {
            this.stopCameraStream()
            this.$emit("on-toggle-selfie-modal")
        },
        async takePhotoButton() {
            if (!this.state.isCameraOpen) return

            this.copyVideoAsCanvasPicture()
        },
        async reopenCamera() {
            if (this.state.isCameraOpen) return

            await this.detectionWebcamAvailability()
        },
        async accessDeviceCamera() {
            try {
                var constraints = (window.constraints = {
                    audio: false,
                    video: this.shouldFaceUser ? true : { facingMode: "environment" }
                })

                const navigatorResponse = await navigator.mediaDevices.getUserMedia(constraints)

                this.$refs.camera.srcObject = navigatorResponse
                this.$refs.camera.play()
                this.stream = navigatorResponse
                this.action("initialized")
            } catch (error) {
                this.action("error")
                throw new Error(error)
            }
        },
        copyVideoAsCanvasPicture() {
            this.state.isPhotoTaken = true
            this.state.isCameraOpen = false
            const video = this.$refs.camera

            var videoRatio = video.videoWidth / video.videoHeight
            var width = video.offsetWidth,
                height = video.offsetHeight
            var elementRatio = width / height
            if (elementRatio > videoRatio) width = height * videoRatio
            else height = width / videoRatio

            const canvasElement = this.$refs.canvas
            var scaleFactor = window.devicePixelRatio > 1.5 ? 1.5 : window.devicePixelRatio

            width = width * scaleFactor
            height = height * scaleFactor
            canvasElement.width = width
            canvasElement.height = height
            const originalCanvas = canvasElement.getContext("2d")
            originalCanvas.imageSmoothingEnabled = true
            originalCanvas.drawImage(this.$refs.camera, 0, 0, width, height)

            this.stopCameraStream()
        },
        stopCameraStream() {
            if (this.$refs.camera.srcObject !== null) {
                let streamTracks = this.$refs.camera.srcObject.getTracks()
                streamTracks.forEach((track) => track.stop())
                this.$refs.camera.srcObject = null
            }
        },
        sendTakenPictureTo() {
            if (!this.state.isPhotoTaken) return null
            const canvasImage = document.getElementById("stream-screen-picture-canvas")
            canvasImage.toBlob((canvasBlobData) => {
                const modifiedFile = new File([canvasBlobData], `blobimage.${canvasBlobData.type.split("/").pop()}`)
                const imageFormData = new FormData()
                imageFormData.append("image", modifiedFile)
                this.$emit("submit-selfie-photo", imageFormData.get("image"))
                this.toggleCloseModal()
            })
        },
        async detectionWebcamAvailability() {
            try {
                const navigatorMediaDevicesResponse = await navigator.mediaDevices.enumerateDevices()
                let hasVideoInput = navigatorMediaDevicesResponse.some((details) => "videoinput" == details.kind)

                if (hasVideoInput) await this.throwCameraAvailability()
            } catch (err) {
                this.throwError(err)
            }
        },
        async throwCameraAvailability() {
            try {
                await this.throwCameraStream()
            } catch (enumerateDevicesError) {
                this.throwError(enumerateDevicesError, "detectionWebcamAvailability")
            }
        },
        async throwCameraStream() {
            try {
                await this.accessDeviceCamera()
                this.action("initialized")
            } catch (errorDetectionCallback) {
                this.throwError(errorDetectionCallback)
            }
        },
        throwError(message, whereFromError) {
            this.action("error")
            throw new Error(message)
        },
        action(mutationType, mutation = {}) {
            this.state = actionCtrl(mutationType, mutation)

            /**
             *
             * @param {*} action - string
             * @description actionCtrl - Action Controller, controls the layers by registered mutationType (String)
             *
             */
            function actionCtrl(action, changes) {
                switch (action) {
                    case "reset": {
                        /** ... */
                    }
                    case "initializing":
                        return {
                            ...initialCameraState,
                            isCameraOpen: false,
                            isPhoneTaken: false,
                            isInitialized: false
                        }
                    case "initialized":
                        return {
                            ...initialCameraState,
                            isCameraOpen: true,
                            isPhoneTaken: false,
                            isInitialized: true,
                            error: { is: false, message: null }
                        }
                    case "error":
                        return {
                            ...initialCameraState,
                            isCameraOpen: false,
                            isPhoneTaken: false,
                            isInitialized: true,
                            error: { is: true, message: initialCameraState.error.message }
                        }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$initial-screen-height: 480px;
$initial-z-index: 10000;
$df-blue: #335d99;

/**
        .plt-camera-stream - is the (child) box element of the <main> modal.
            And that block element opens and can be visible to user while it is available/accessible

        .plt-camera-focus-box - when the user has operated the camera, 
            the focus box (AKA: focus-indicator) will appear on the <video/> element.
            
        Down below is an example of how to customize and make your focus-box/indicator:

        .plt-camera-stream (You can use the class name called .plt-camera-focus-box)  {
            position: relative;

            &::after {
                content: "";
                top: 50%;
                left: 50%;
                width: 350px;
                height: 350px;
                position: absolute;
                border: 1.5px solid red;
                transform: translate(-50%, -50%);
            }
        }
     */

@mixin display-flex() {
    display: flex;
    align-items: center;
}

.image-upload-camera {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $initial-z-index;
    position: fixed;

    &-modal {
        height: 100%;
        width: 100%;
        backdrop-filter: blur(9px);
        background-color: rgba($color: $df-blue, $alpha: 0.1);

        @include display-flex;
        justify-content: center;
    }
}

.cameraModal {
    width: 50%;

    &__header {
        padding: 7px 12px;
        height: 3rem;
        @include display-flex;
        justify-content: flex-end;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: var(--pl-dialog-top-bar);

        &--close {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background-color: white;
            border: 1px solid #426aa0;

            @include display-flex;
            justify-content: center;
            cursor: pointer;

            & i {
                font-size: 0.85rem;
                color: $df-blue;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &__main {
        height: $initial-screen-height;
        position: relative;
        background-color: #000;

        &-rear {
            position: absolute;
            top: 92%;
            right: 3%;
            padding: 5px;
            border-radius: 40%;
            background: #ffffff69;
            object-fit: cover;

            * {
                object-fit: cover;
            }

            i {
                display: flex;
                justify-content: center;
                color: #000000c6;
            }
        }

        &--display {
            @include display-flex;
            height: 100%;
            align-items: center;
            justify-content: center;

            & .stream-video {
                width: 100%;
                height: $initial-screen-height;
                object-fit: cover;
            }

            & .capture-canvas {
                height: 100% !important;
                object-fit: cover;
            }
        }

        &--dialog {
            left: 0;
            top: 0;
            width: 100%;
            height: 480px;
            position: absolute;
            background-color: #000;
            @include display-flex;
            flex-direction: column;
            justify-content: flex-end;

            & .capture-error-message {
                width: 100%;
                color: #fff;
                padding: 10px 5px;
                @include display-flex;
                background-color: #fc171f;
            }

            & .capture-error-icon {
                top: 50%;
                left: 50%;
                opacity: 0.9;
                color: #fc171f;
                font-size: 4rem;
                cursor: pointer;
                transform: translate(-50%, -50%);
                position: absolute;
            }
        }

        &--circularProgress {
            width: 100%;
            height: 100%;
            background-color: #000;
            @include display-flex;
            justify-content: center;

            top: 0;
            left: 0;
            position: absolute;

            & .progress-icon {
                width: 75px;
                height: 75px;
                border-radius: 50%;
                background-color: transparent;
                border: 3px solid var(--pl-btn-link-hover);
                border-top-color: var(--pl-dialog-top-bar);
                -webkit-animation: 1s spin linear infinite;
                animation: 1s spin linear infinite;
            }
        }
    }

    &__subheader {
        padding: 6px;
        @include display-flex;
        justify-content: center;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: var(--pl-dialog-top-bar);

        &--options {
            padding: 8px;
            @include display-flex;
            justify-content: center;

            & .camera-option-button {
                width: 80px;
                padding: 4px;
                cursor: pointer;
                border-radius: 6px;
                @include display-flex;
                justify-content: center;
                background-color: var(--pl-btn-link-hover);

                & i {
                    color: var(--pl-dialog-top-bar);
                    font-size: 25px;
                }

                &:hover {
                    opacity: 0.8;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }
        .rearcamera {
            transform: scaleX(-X);
        }
    }

    @media only screen and (max-width: 56.25em) {
        width: 90%;

        & .capture-error-message {
            font-size: 12px;
            & i {
                font-size: 18px;
            }
        }

        & .stream-screen {
            & canvas {
                width: 100%;
            }
            &::after {
                width: 300px;
                height: 290px;
            }
        }
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
