<template>
    <BaseControl v-bind="$attrs">
        <b-form-row class="flex-grow-1">
            <ImageUploadMultipleItem
                v-bind="{ 'element-id': $attrs['element-id'] }"
                :remote="f"
                @deleted="remoteFiles.splice(index, 1)"
                @preview="showPreview(index)"
                :item-width="computedItemWidth"
                v-for="(f, index) in remoteFiles"
                :key="f.id"
            />

            <ImageUploadMultipleItem
                v-bind="{ 'element-id': $attrs['element-id'] }"
                :key="f.id"
                :info="f"
                v-model="localFilesId[index]"
                @cancel="selectedFiles.splice(index, 1)"
                @preview="showPreview(index + remoteFiles.length)"
                :item-width="computedItemWidth"
                @deleted="selectedFiles.splice(index, 1)"
                v-for="(f, index) in selectedFiles"
            />

            <vue-image-lightbox-carousel
                ref="slider"
                :images="slideImages"
                :show="showImageSlider"
                @close="showImageSlider = false"
            >
            </vue-image-lightbox-carousel>

            <label id="imageField" @drop.stop.prevent="onImageDropped($event)" @dragover.stop.prevent>
                <ImageUploadMultipleAdd
                    v-if="!isDisabledOrReadOnly && canSelectFile"
                    :item-width="computedItemWidth"
                    @pick="$refs.input.click()"
                />
                <input
                    type="file"
                    :accept="allowedExtensions"
                    @input="onImagesSelected($event.target.files)"
                    class="custom-file-input"
                    ref="input"
                    multiple
                />
            </label>
        </b-form-row>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import UploadMultipleItem from "@Platon/components/form/controls/UploadMultipleItem.vue"
import ImageUploadMultipleAdd from "@Platon/components/form/controls/ImageUploadMultipleAdd.vue"
import ImageUploadMultipleItem from "@Platon/components/form/controls/ImageUploadMultipleItem.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import UploadMultipleLimitsMixin from "@Platon/components/form/controls/UploadMultipleLimitsMixin"
import ToastMixin from "@Platon/mixins/ToastMixin"
import VueImageLightboxCarousel from "vue-img-lightbox-fixed"
import { base64ToFile } from "@Platon/core/helpers/FileHelpers"
import ImageControlCropMixin from "@Platon/components/form/controls/ImageControlCropMixin"

export default {
    name: "ImageUploadMultiple",
    components: {
        ImageUploadMultipleAdd,
        ImageUploadMultipleItem,
        UploadMultipleItem,
        BaseControl,
        VueImageLightboxCarousel
    },

    mixins: [InputControlMixin, ToastMixin, UploadMultipleLimitsMixin, ImageControlCropMixin],

    data() {
        return {
            localFilesId: [],
            selectedFiles: [],
            showImageSlider: false,
            itemWidth: undefined
        }
    },

    mounted() {
        this.emitValue()
    },

    computed: {
        remoteFiles() {
            return this.item.data || []
        },

        slideImages() {
            return this.remoteFiles
                .filter((x) => x)
                .map((f) => ({ path: platonFileUrl(f.id), caption: this.captionForRemoteFile(f) }))
                .concat(
                    this.localFilesId
                        .filter((x) => x)
                        .map((id, index) => ({
                            path: platonFileUrl(id),
                            caption: this.captionForLocalFile(id, index)
                        }))
                )
        },

        computedItemWidth() {
            return this.itemWidth || this.item.itemWidth || "150px"
        }
    },

    provide() {
        return {
            item: this.item,
            uploadControl: this
        }
    },

    methods: {
        emitValue() {
            let f = this.remoteFiles.map((x) => x.id)
            f.push(...this.localFilesId)

            this.updateLocal(f)
        },

        setItemsWidth(width) {
            this.itemWidth = width
        },

        showPreview(index) {
            this.showImageSlider = true
            this.$refs.slider.showImage(index)
        },

        /**
         * @param {PlatonFile} file
         * @return {string}
         */
        captionForRemoteFile(file) {
            return `${file.name}.${file.extension}, ${this.$options.filters.prettyBytes(file.size)}`
        },

        /**
         * @param {string} fileId
         * @param {number} index
         */
        captionForLocalFile(fileId, index) {
            /**
             * @type File
             */
            let file = this.selectedFiles[index].file

            return `${file.name}, ${this.$options.filters.prettyBytes(file.size)}`
        },

        /**
         * @param base64
         * @param filename
         * @param type
         */
        async uploadBase64File(base64, filename = "image.jpg", type = "image/jpeg") {
            return this.onImagesSelected([base64ToFile(base64, filename, type)])
        },

        /**
         * @param {File[]} images
         */
        async onImagesSelected(images) {
            const cropOptions = this.getCropOptionsForElement(this.item)

            if (cropOptions) {
                return this.onFilesSelected(await this.tryToCrop(images, cropOptions))
            } else {
                return this.onFilesSelected(images)
            }
        },

        onImageDropped(event) {
            let fileList = event.dataTransfer.files
            this.onImagesSelected(fileList)
        }
    },

    watch: {
        localFilesId(val) {
            this.emitValue()
        },

        remoteFiles() {
            this.emitValue()
        }
    }
}
</script>

<style scoped>
#imageField {
    position: relative;
}

#imageField input {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
